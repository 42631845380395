import { QuickSettings } from "@/components/QuickSettings";
import { UserAggregateMetaNormaEnterpriseWebModeEnum as AppMode } from "@norma-bi/bi-api";
import { SimpleButton } from "./SimpleButton";
import { SpotterButton } from "./SpotterButton";
import { SearchButton } from "./SearchButton";
import { useLocation, useNavigate } from "react-router-dom";

type HeaderProps = {
  mode: AppMode;
};

export function Header({ mode }: HeaderProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePath = location.pathname.startsWith("/pinboard") || location.pathname == "/";
  const isSettingsPath = location.pathname.startsWith("/settings");

  return (
    <div className="flex-shrink-0 mt-2">
      <div className="flex justify-between items-center h-12 mx-12 bg-white rounded-lg text-sm">
        <div className="flex gap-6 px-6">
          <SimpleButton
            icon="HomeAlt"
            label="Home"
            isActive={isHomePath}
            onClick={() => navigate("/")}
          />
          <SearchButton />
          <SpotterButton />
          <SimpleButton
            icon="Settings"
            label="Settings"
            isActive={isSettingsPath}
            onClick={() => navigate("/settings")}
          />
        </div>
        <div className="px-6">
          <QuickSettings mode={mode} hideArrows={true} hideBusinessName={false} />
        </div>
      </div>
    </div>
  );
}
