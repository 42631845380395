import { useTooltip, TooltipContent } from "@/components/Tooltip";

export function useLiveboardTooltip<T extends HTMLElement>(
  content: TooltipContent,
  delay: number = 1000,
) {
  return useTooltip<T>(content, {
    delay,
    position: (rect) => ({
      x: rect.left + 1.18 * rect.width,
      y: rect.top - 1.6 * rect.height,
    }),
  });
}
