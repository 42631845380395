import React, { MouseEventHandler } from "react";
import { TooltipContext } from "./context";

export type TooltipContent = string | (() => string | null);
export type TooltipPosition = (rect: DOMRect) => { x: number; y: number };

export function useTooltip<T extends HTMLElement>(
  content: TooltipContent,
  options?: Partial<{
    enabled: boolean;
    delay: number;
    position: TooltipPosition;
  }>,
): React.HTMLProps<T> {
  const delay = options?.delay ?? 1000;
  const enabled = options?.enabled ?? true;

  const position = React.useMemo(
    () =>
      options?.position ??
      ((rect: DOMRect) => ({
        x: rect.left,
        y: rect.top,
      })),
    [options?.position],
  );

  const { setTooltip } = React.useContext(TooltipContext);
  const tooltipTimeoutRef = React.useRef<number | null>(null);

  const showTooltip = React.useCallback(
    (content: string, x: number, y: number) => {
      if (!enabled) return;

      tooltipTimeoutRef.current = window.setTimeout(() => {
        setTooltip({ content, x, y });
      }, delay);
    },
    [delay, setTooltip, enabled],
  );

  const hideTooltip = React.useCallback(() => {
    if (tooltipTimeoutRef.current !== null) {
      clearTimeout(tooltipTimeoutRef.current);
      tooltipTimeoutRef.current = null;
    }
    setTooltip(null);
  }, [setTooltip]);

  const onMouseEnter: MouseEventHandler = React.useCallback(
    (evt) => {
      const rect = (evt.currentTarget as HTMLElement).getBoundingClientRect();
      const c = typeof content === "function" ? content() : content;
      if (c) {
        const { x, y } = position(rect);
        showTooltip(c, x, y);
      }
    },
    [content, showTooltip, position],
  );

  const onMouseLeave: MouseEventHandler = React.useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  React.useEffect(() => {
    if (!enabled) {
      hideTooltip();
    }
  }, [enabled, hideTooltip]);

  if (!enabled) {
    return {};
  }

  return {
    onMouseEnter,
    onMouseLeave,
  };
}
