import React from "react";
import { SimpleButton } from "./SimpleButton";
import { useSearchDataFeatureDataSources } from "@/hooks/useSearchDataFeatureDataSources";
import { SvgIcon } from "@/svg";
import * as Sentry from "@sentry/react";
import { useFloating, useHover, useInteractions, safePolygon } from "@floating-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { selectIcon } from "./selectIcon";
import { useDataSourceStore } from "@/stores/DataSourceStore";

export function SpotterButton() {
  const { data: dataSources, isLoading, error } = useSearchDataFeatureDataSources();
  const dataSourceId = useDataSourceStore((state) => state.spotterDataSourceId);
  const setDataSourceId = useDataSourceStore((state) => state.setSpotterDataSourceId);
  const navigate = useNavigate();
  const location = useLocation();
  const isSpotterPath = location.pathname === "/spotter";

  const [isOpen, setIsOpen] = React.useState(false);

  const floating = useFloating<HTMLDivElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(floating.context, {
    handleClose: safePolygon(),
  });

  const interactions = useInteractions([hover]);

  React.useEffect(() => {
    if (!dataSources || dataSources.length === 0) {
      return;
    }

    const fallbackDataSource = dataSources[0];

    if (!dataSourceId) {
      setDataSourceId(fallbackDataSource.id);
      return;
    }

    const dataSourceExists = Boolean(dataSources.find((ds) => ds.id === dataSourceId));

    if (!dataSourceExists) {
      Sentry.captureEvent({
        level: "error",
        message: `Datasource with id '${dataSourceId}' not found, falling back to the first available.`,
      });
      setDataSourceId(fallbackDataSource.id);
    }
  }, [dataSources, dataSourceId, setDataSourceId]);

  const selectedDataSource = React.useMemo(() => {
    return dataSources?.find((d) => d.id === dataSourceId);
  }, [dataSources, dataSourceId]);

  if (!selectedDataSource || !dataSources) {
    return null;
  }

  function onSelectDataSource(sourceId: string) {
    if (sourceId !== dataSourceId) {
      setDataSourceId(sourceId);
      if (!isSpotterPath) {
        navigate("/spotter");
      }
    }
    setIsOpen(false);
  }

  const handleSpotterClick = () => {
    if (dataSources.length === 1) {
      onSelectDataSource(dataSources[0].id);
      if (!isSpotterPath) {
        navigate("/spotter");
      }
      return;
    }
    if (!isSpotterPath) {
      navigate("/spotter");
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <div ref={floating.refs.setReference} {...interactions.getReferenceProps()}>
        <SimpleButton
          icon="FileNote"
          label="Spotter"
          onClick={handleSpotterClick}
          isActive={isSpotterPath || isOpen}
          showChevron={dataSources.length > 1}
        />
      </div>
      {isOpen && dataSources.length > 1 && (
        <div
          ref={floating.refs.setFloating}
          style={floating.floatingStyles}
          {...interactions.getFloatingProps()}
          className="dropdown-container absolute mt-6 bg-white rounded-lg shadow-lg py-2 min-w-[200px] z-50"
        >
          {isLoading && <div className="px-4 py-2">Loading...</div>}
          {error && <div className="px-4 py-2 text-red-500">Error loading data sources</div>}
          {dataSources.map((source) => {
            const IconComponent = selectIcon(source.id);
            return (
              <button
                key={source.id}
                className="w-full px-4 py-2 text-left hover:text-dark focus:outline-none focus:bg-gray-100 flex items-center justify-between group gap-x-2"
                onClick={() => onSelectDataSource(source.id)}
                title={source.name}
              >
                <span
                  className={`
                    flex items-center gap-x-2 min-w-0 flex-1 group-hover:text-dark
                    ${dataSourceId === source.id ? "text-dark content-label-subhead" : "text-feintGray text-sm"}
                  `}
                >
                  <IconComponent />
                  <span className="truncate">{source.name}</span>
                </span>
                <span
                  className={
                    dataSourceId === source.id
                      ? "opacity-100 text-success group-hover:opacity-100 transition-opacity flex-shrink-0"
                      : "opacity-0 group-hover:opacity-100 transition-opacity flex-shrink-0"
                  }
                >
                  <SvgIcon icon="Check" />
                </span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
