import clsx from "clsx";
import { SvgIcon } from "@/svg";

type CreateLiveboardButtonProps = {
  isExpanded: boolean;
  onClick: () => void;
};

export function CreateLiveboardButton({ isExpanded, onClick }: CreateLiveboardButtonProps) {
  return (
    <button
      className={clsx(
        "relative rounded-lg cursor-pointer w-full",
        "flex items-center",
        "text-feintGray hover:bg-white hover:text-dark",
        isExpanded ? "py-3 pr-3 pl-4 space-x-2" : "p-2 h-9",
      )}
      onClick={onClick}
    >
      <div
        className={clsx("flex items-center gap-4", isExpanded ? "flex-1" : "w-full justify-center")}
      >
        <SvgIcon icon="Plus" />
        {isExpanded && <span className="truncate font-medium">Create Liveboard</span>}
      </div>
    </button>
  );
}
