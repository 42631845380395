import React from "react";
import { SettingsUsersTable } from "./SettingsUsersTable";
import { PulseLoader } from "react-spinners";
import { isAllBusiness } from "@/api";
import { useBusinessContext } from "@/hooks/useBusinessContext";
import { UserRowModel } from "./SettingsUsersTable";
import { UsersSettingsTab, UsersSettingsTabs } from "./tabs";
import { SearchBox } from "@/components/SearchBox";
import clsx from "clsx";
import { useAuth } from "@/Auth";
import { useGetInactiveFoodicsAccounts } from "@/hooks/useGetInactiveFoodicsAccounts";
import { mergeUserEmails } from "@/utils/mergeUserEmails";
import { useGetUsers } from "@/hooks/useGetUsers";

export function SettingsUsers() {
  const businessContext = useBusinessContext();
  const auth = useAuth();
  const userIsDemo = auth.user?.is_demo ?? false;

  const [selectedTab, setSelectedTab] = React.useState<UsersSettingsTab>(UsersSettingsTabs.active);
  const [searchTerm, setSearchTerm] = React.useState("");

  const usersQuery = useGetUsers({ businessId: businessContext.business?.id, userIsDemo });

  const inactiveFoodicsAccountsQuery = useGetInactiveFoodicsAccounts({
    businessId: businessContext.business?.id || "",
    userIsDemo,
    enabled:
      selectedTab === UsersSettingsTabs.inactive &&
      businessContext.business &&
      !isAllBusiness(businessContext.business.id),
  });

  const users = React.useMemo<UserRowModel[]>(() => {
    if (!usersQuery.isSuccess) return [];
    let users = usersQuery.data;
    if (selectedTab === UsersSettingsTabs.active) {
      users = usersQuery.data.filter((user) => user.is_active);
    } else if (selectedTab === UsersSettingsTabs.pending) {
      users = usersQuery.data.filter((user) => !user.is_active);
    }

    if (searchTerm !== "") {
      users = users.filter((u) =>
        mergeUserEmails(u).toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return users.map((u) => {
      return {
        id: u.id,
        name: u.name,
        email: mergeUserEmails(u),
        branchesCount: u.venues_count,
        businessesCount: u.businesses_count,
        is_root: u.is_root,
      };
    });
  }, [usersQuery.isSuccess, usersQuery.data, selectedTab, searchTerm]);

  const inactiveFoodicsAccounts = React.useMemo<UserRowModel[]>(() => {
    if (!inactiveFoodicsAccountsQuery.isSuccess) return [];

    const acc = inactiveFoodicsAccountsQuery.data.filter((a) => {
      return a.email.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const mappedAccounts = acc.map((a) => {
      return {
        id: a.id,
        name: a.name,
        email: a.email,
        branchesCount: a.branches?.length ?? 0,
        roles: a.roles?.map((r) => r.name) ?? [],
      };
    });

    return mappedAccounts.sort((a, b) => {
      const countA = a.branchesCount || 0;
      const countB = b.branchesCount || 0;
      return countB - countA;
    });
  }, [inactiveFoodicsAccountsQuery.isSuccess, inactiveFoodicsAccountsQuery.data, searchTerm]);

  const LoadingContent = (
    <div className="flex justify-center items-center py-20">
      <PulseLoader color="lightgray" loading={true} size={15} speedMultiplier={0.6} />
    </div>
  );

  const Content = () => {
    if (selectedTab === UsersSettingsTabs.inactive) {
      if (!businessContext.business) {
        return null;
      }
      if (isAllBusiness(businessContext.business.id)) {
        return (
          <div className="flex justify-center items-center bg-white h-[80%] w-full px-[19%]">
            <div className="font-medium text-feintGray text-center text-sm">
              You cannot invite inactive users while in the{" "}
              <span className="text-sematicRed text-center">"All Businesses"</span> context. Please
              choose a single business from the profile tab in order to see the inactive users of
              that business.
            </div>
          </div>
        );
      }
      if (inactiveFoodicsAccountsQuery.isPending) {
        return LoadingContent;
      }
      return inactiveFoodicsAccounts.length === 0 ? (
        <div className="flex justify-center items-center">
          <span>No results</span>
        </div>
      ) : (
        <SettingsUsersTable accounts={inactiveFoodicsAccounts} selectedTab={selectedTab} />
      );
    }

    if (usersQuery.isPending) {
      return LoadingContent;
    }

    return users.length === 0 ? (
      <div className="flex justify-center items-center">
        <span>No results</span>
      </div>
    ) : (
      <SettingsUsersTable accounts={users} selectedTab={selectedTab} />
    );
  };

  const [tabs] = React.useState([
    UsersSettingsTabs.active,
    UsersSettingsTabs.pending,
    UsersSettingsTabs.inactive,
  ]);

  return (
    <div className="h-full flex flex-col">
      <div className="flex space-x-4 items-center">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={clsx("px-6 py-2 rounded-b-none border-b-2", {
              "border-dark text-dark font-medium": selectedTab === tab,
              "border-light text-light hover:text-dark hover:border-dark": selectedTab !== tab,
            })}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
        <div className="!ml-auto flex">
          <SearchBox term={searchTerm} onTermChanged={setSearchTerm} placeholder="Search email" />
        </div>
      </div>
      <div className="pt-8 flex-1 min-h-0 overflow-hidden">
        <Content />
      </div>
    </div>
  );
}
