/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountCumulativeInfo,
  AdminLoginRequest,
  BranchAppsNetSalesResponse,
  BranchGeneralMetrics,
  BranchNetSalesTimeseriesResponse,
  BranchOrderSourcesNetSalesResponse,
  BranchOrderTagsNetSalesResponse,
  BranchOrderTypesNetSalesResponse,
  BranchesNetSalesTimeseriesResponse,
  BranchesReportResponse,
  BudgetResponse,
  BudgetValidationErrorResponse,
  Business,
  BusinessInfo,
  ChangeBusinessContextRequest,
  ChangeModeRequest,
  ChangePasswordRequest,
  Currency,
  CustomerSuccessInfo,
  GetBusinessesResponse,
  GetInactiveFoodicsUsersResponse,
  GetInitialOnboardingFoodicsAccountsResponse,
  GetOnboardBusinessTokenResponse,
  GetThoughtspotDataSourcesResponse,
  GetThoughtspotLiveboardsResponse,
  GetThoughtspotSchedulesResponse,
  GetUsersByBusinessResponse,
  GetUsersByOrganizationResponse,
  ImpersonateResponse,
  InitialOnboardFoodicsUsersRequest,
  InviteFoodicsUsersRequest,
  InvoicesCumulativeInfo,
  Licenses,
  LiveboardCategory,
  LoginRequest,
  LoginResponse,
  MenuMixBranchProductsWithNetSalesAndQuantityListResponse,
  MenuMixBusinessBranchesWithQuantityResponse,
  MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse,
  MenuMixBusinessProductCategoriesWithNetSalesListResponse,
  MenuMixBusinessProductCategoryBranchesWithNetSalesResponse,
  MenuMixBusinessProductCategoryOrderTagsWithNetSalesResponse,
  MenuMixBusinessProductOrderTagsWithNetSalesAndQuantityResponse,
  MenuMixBusinessProductsQuantityTimeseriesResponse,
  MenuMixBusinessProductsWithNetSalesAndQuantityListResponse,
  MobileImpersonatorResponse,
  OnboardFoodicsUsersRequest,
  ProfileInvoicesResponse,
  ProfileSupportTicketsResponse,
  RequestResetPasswordRequest,
  RequestResetPasswordResponse,
  ResetPasswordRequest,
  StatusResponse,
  ThoughtspotLiveboardsCreateResponse,
  ThoughtspotToken,
  Timezone,
  Token,
  UpdateBusinessRequest,
  UserAggregate,
} from '../models/index';
import {
    AccountCumulativeInfoFromJSON,
    AccountCumulativeInfoToJSON,
    AdminLoginRequestFromJSON,
    AdminLoginRequestToJSON,
    BranchAppsNetSalesResponseFromJSON,
    BranchAppsNetSalesResponseToJSON,
    BranchGeneralMetricsFromJSON,
    BranchGeneralMetricsToJSON,
    BranchNetSalesTimeseriesResponseFromJSON,
    BranchNetSalesTimeseriesResponseToJSON,
    BranchOrderSourcesNetSalesResponseFromJSON,
    BranchOrderSourcesNetSalesResponseToJSON,
    BranchOrderTagsNetSalesResponseFromJSON,
    BranchOrderTagsNetSalesResponseToJSON,
    BranchOrderTypesNetSalesResponseFromJSON,
    BranchOrderTypesNetSalesResponseToJSON,
    BranchesNetSalesTimeseriesResponseFromJSON,
    BranchesNetSalesTimeseriesResponseToJSON,
    BranchesReportResponseFromJSON,
    BranchesReportResponseToJSON,
    BudgetResponseFromJSON,
    BudgetResponseToJSON,
    BudgetValidationErrorResponseFromJSON,
    BudgetValidationErrorResponseToJSON,
    BusinessFromJSON,
    BusinessToJSON,
    BusinessInfoFromJSON,
    BusinessInfoToJSON,
    ChangeBusinessContextRequestFromJSON,
    ChangeBusinessContextRequestToJSON,
    ChangeModeRequestFromJSON,
    ChangeModeRequestToJSON,
    ChangePasswordRequestFromJSON,
    ChangePasswordRequestToJSON,
    CurrencyFromJSON,
    CurrencyToJSON,
    CustomerSuccessInfoFromJSON,
    CustomerSuccessInfoToJSON,
    GetBusinessesResponseFromJSON,
    GetBusinessesResponseToJSON,
    GetInactiveFoodicsUsersResponseFromJSON,
    GetInactiveFoodicsUsersResponseToJSON,
    GetInitialOnboardingFoodicsAccountsResponseFromJSON,
    GetInitialOnboardingFoodicsAccountsResponseToJSON,
    GetOnboardBusinessTokenResponseFromJSON,
    GetOnboardBusinessTokenResponseToJSON,
    GetThoughtspotDataSourcesResponseFromJSON,
    GetThoughtspotDataSourcesResponseToJSON,
    GetThoughtspotLiveboardsResponseFromJSON,
    GetThoughtspotLiveboardsResponseToJSON,
    GetThoughtspotSchedulesResponseFromJSON,
    GetThoughtspotSchedulesResponseToJSON,
    GetUsersByBusinessResponseFromJSON,
    GetUsersByBusinessResponseToJSON,
    GetUsersByOrganizationResponseFromJSON,
    GetUsersByOrganizationResponseToJSON,
    ImpersonateResponseFromJSON,
    ImpersonateResponseToJSON,
    InitialOnboardFoodicsUsersRequestFromJSON,
    InitialOnboardFoodicsUsersRequestToJSON,
    InviteFoodicsUsersRequestFromJSON,
    InviteFoodicsUsersRequestToJSON,
    InvoicesCumulativeInfoFromJSON,
    InvoicesCumulativeInfoToJSON,
    LicensesFromJSON,
    LicensesToJSON,
    LiveboardCategoryFromJSON,
    LiveboardCategoryToJSON,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    MenuMixBranchProductsWithNetSalesAndQuantityListResponseFromJSON,
    MenuMixBranchProductsWithNetSalesAndQuantityListResponseToJSON,
    MenuMixBusinessBranchesWithQuantityResponseFromJSON,
    MenuMixBusinessBranchesWithQuantityResponseToJSON,
    MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseFromJSON,
    MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseToJSON,
    MenuMixBusinessProductCategoriesWithNetSalesListResponseFromJSON,
    MenuMixBusinessProductCategoriesWithNetSalesListResponseToJSON,
    MenuMixBusinessProductCategoryBranchesWithNetSalesResponseFromJSON,
    MenuMixBusinessProductCategoryBranchesWithNetSalesResponseToJSON,
    MenuMixBusinessProductCategoryOrderTagsWithNetSalesResponseFromJSON,
    MenuMixBusinessProductCategoryOrderTagsWithNetSalesResponseToJSON,
    MenuMixBusinessProductOrderTagsWithNetSalesAndQuantityResponseFromJSON,
    MenuMixBusinessProductOrderTagsWithNetSalesAndQuantityResponseToJSON,
    MenuMixBusinessProductsQuantityTimeseriesResponseFromJSON,
    MenuMixBusinessProductsQuantityTimeseriesResponseToJSON,
    MenuMixBusinessProductsWithNetSalesAndQuantityListResponseFromJSON,
    MenuMixBusinessProductsWithNetSalesAndQuantityListResponseToJSON,
    MobileImpersonatorResponseFromJSON,
    MobileImpersonatorResponseToJSON,
    OnboardFoodicsUsersRequestFromJSON,
    OnboardFoodicsUsersRequestToJSON,
    ProfileInvoicesResponseFromJSON,
    ProfileInvoicesResponseToJSON,
    ProfileSupportTicketsResponseFromJSON,
    ProfileSupportTicketsResponseToJSON,
    RequestResetPasswordRequestFromJSON,
    RequestResetPasswordRequestToJSON,
    RequestResetPasswordResponseFromJSON,
    RequestResetPasswordResponseToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    StatusResponseFromJSON,
    StatusResponseToJSON,
    ThoughtspotLiveboardsCreateResponseFromJSON,
    ThoughtspotLiveboardsCreateResponseToJSON,
    ThoughtspotTokenFromJSON,
    ThoughtspotTokenToJSON,
    TimezoneFromJSON,
    TimezoneToJSON,
    TokenFromJSON,
    TokenToJSON,
    UpdateBusinessRequestFromJSON,
    UpdateBusinessRequestToJSON,
    UserAggregateFromJSON,
    UserAggregateToJSON,
} from '../models/index';

export interface DeleteUsersUserIdRequest {
    user_id: string;
}

export interface FavoriteThoughtspotLiveboardRequest {
    liveboard_id: string;
}

export interface GetBranchAppsNetSalesRequest {
    branch_id: string;
    time_period: GetBranchAppsNetSalesTimePeriodEnum;
}

export interface GetBranchGeneralMetricsRequest {
    branch_id: string;
    time_period: GetBranchGeneralMetricsTimePeriodEnum;
}

export interface GetBranchNetSalesTimeseriesRequest {
    branch_id: string;
    time_period: GetBranchNetSalesTimeseriesTimePeriodEnum;
}

export interface GetBranchOrderSourcesNetSalesRequest {
    branch_id: string;
    time_period: GetBranchOrderSourcesNetSalesTimePeriodEnum;
}

export interface GetBranchOrderTagsNetSalesRequest {
    branch_id: string;
    time_period: GetBranchOrderTagsNetSalesTimePeriodEnum;
}

export interface GetBranchOrderTypesNetSalesRequest {
    branch_id: string;
    time_period: GetBranchOrderTypesNetSalesTimePeriodEnum;
}

export interface GetBranchesNetSalesTimeseriesRequest {
    business_id: string;
    time_period: GetBranchesNetSalesTimeseriesTimePeriodEnum;
}

export interface GetBranchesReportRequest {
    business_id: string;
    time_period: GetBranchesReportTimePeriodEnum;
}

export interface GetBudgetRequest {
    business_id: string;
    resolution: GetBudgetResolutionEnum;
    year: number;
}

export interface GetBudgetTemplateRequest {
    business_id: string;
    resolution: GetBudgetTemplateResolutionEnum;
    year: number;
}

export interface GetBudgetXlsxRequest {
    business_id: string;
    resolution: GetBudgetXlsxResolutionEnum;
    year: number;
}

export interface GetInactiveUsersByBusinessRequest {
    business_id: string;
}

export interface GetIntegrationsFoodicsOnboardBusinessRequest {
    token: string;
}

export interface GetMenuMixBranchProductsWithNetSalesAndQuantityRequest {
    branch_id: string;
    time_period: GetMenuMixBranchProductsWithNetSalesAndQuantityTimePeriodEnum;
    page: number;
    page_size: number;
    order_by: GetMenuMixBranchProductsWithNetSalesAndQuantityOrderByEnum;
}

export interface GetMenuMixBusinessBranchesWithQuantityRequest {
    business_id: string;
    time_period: GetMenuMixBusinessBranchesWithQuantityTimePeriodEnum;
}

export interface GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityRequest {
    business_id: string;
    time_period: GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityTimePeriodEnum;
    product_id: string;
}

export interface GetMenuMixBusinessProductCategoriesWithNetSalesRequest {
    business_id: string;
    time_period: GetMenuMixBusinessProductCategoriesWithNetSalesTimePeriodEnum;
    page: number;
    page_size: number;
}

export interface GetMenuMixBusinessProductCategoryBranchesWithNetSalesRequest {
    business_id: string;
    time_period: GetMenuMixBusinessProductCategoryBranchesWithNetSalesTimePeriodEnum;
    category_id: string;
}

export interface GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesRequest {
    business_id: string;
    time_period: GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesTimePeriodEnum;
    category_id: string;
}

export interface GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityRequest {
    business_id: string;
    time_period: GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityTimePeriodEnum;
    product_id: string;
}

export interface GetMenuMixBusinessProductsQuantityTimeseriesRequest {
    business_id: string;
    time_period: GetMenuMixBusinessProductsQuantityTimeseriesTimePeriodEnum;
}

export interface GetMenuMixBusinessProductsWithNetSalesAndQuantityRequest {
    business_id: string;
    time_period: GetMenuMixBusinessProductsWithNetSalesAndQuantityTimePeriodEnum;
    page: number;
    page_size: number;
    order_by: GetMenuMixBusinessProductsWithNetSalesAndQuantityOrderByEnum;
}

export interface GetProfileBusinessAccountCumulativeInfoRequest {
    business_id: string;
}

export interface GetProfileBusinessCustomerSuccessRequest {
    business_id: string;
}

export interface GetProfileBusinessInfoRequest {
    business_id: string;
}

export interface GetProfileBusinessInvoicesRequest {
    business_id: string;
    page: number;
    page_size: number;
}

export interface GetProfileBusinessInvoicesCumulativeInfoRequest {
    business_id: string;
}

export interface GetProfileBusinessLicensesRequest {
    business_id: string;
}

export interface GetProfileBusinessSupportTicketsRequest {
    business_id: string;
    page: number;
    page_size: number;
}

export interface GetRefreshRequest {
    token: string;
}

export interface GetSettingsCurrencyRequest {
    business_id: string;
}

export interface GetSettingsTimezoneRequest {
    business_id: string;
}

export interface GetUsersByBusinessRequest {
    business_id: string;
}

export interface ImpersonateRequest {
    id: string;
}

export interface PatchBusinessesBusinessIdRequest {
    business_id: string;
    UpdateBusinessRequest?: UpdateBusinessRequest;
}

export interface PostBudgetRequest {
    business_id: string;
    year: number;
    body?: Blob;
}

export interface PostChangePasswordRequest {
    ChangePasswordRequest?: ChangePasswordRequest;
}

export interface PostIntegrationsFoodicsAccountsInitialOnboardingRequest {
    InitialOnboardFoodicsUsersRequest?: InitialOnboardFoodicsUsersRequest;
}

export interface PostInviteFoodicsAccountsRequest {
    InviteFoodicsUsersRequest?: InviteFoodicsUsersRequest;
}

export interface PostLoginRequest {
    LoginRequest?: LoginRequest;
}

export interface PostOnboardUsersToBusinessRequest {
    business_id: string;
    OnboardFoodicsUsersRequest?: OnboardFoodicsUsersRequest;
}

export interface PostOpsLoginRequest {
    AdminLoginRequest?: AdminLoginRequest;
}

export interface PostRequestResetPasswordRequest {
    RequestResetPasswordRequest?: RequestResetPasswordRequest;
}

export interface PostResetPasswordRequest {
    ResetPasswordRequest?: ResetPasswordRequest;
}

export interface PostSelfBusinessContextRequest {
    ChangeBusinessContextRequest?: ChangeBusinessContextRequest;
}

export interface PostSelfEnterpriseWebAppLiveboardsOrderRequest {
    LiveboardCategory?: Array<LiveboardCategory>;
}

export interface PostSelfEnterpriseWebAppModeRequest {
    ChangeModeRequest?: ChangeModeRequest;
}

export interface PostThoughtspotLiveboardsCreateRequest {
    liveboard_name: string;
}

export interface UnfavoriteThoughtspotLiveboardRequest {
    liveboard_id: string;
}

export interface UsersPromoteUserRequest {
    user_id: string;
}

/**
 * DefaultApi - interface
 * 
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Delete user
     * @param {string} user_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteUsersUserIdRaw(requestParameters: DeleteUsersUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete user
     */
    deleteUsersUserId(requestParameters: DeleteUsersUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Favorite thoughtspot liveboard to favorites
     * @param {string} liveboard_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    favoriteThoughtspotLiveboardRaw(requestParameters: FavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Favorite thoughtspot liveboard to favorites
     */
    favoriteThoughtspotLiveboard(requestParameters: FavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get net sales per app
     * @param {string} branch_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchAppsNetSalesRaw(requestParameters: GetBranchAppsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchAppsNetSalesResponse>>;

    /**
     * Get net sales per app
     */
    getBranchAppsNetSales(requestParameters: GetBranchAppsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchAppsNetSalesResponse>;

    /**
     * 
     * @summary Get general metrics of a branch
     * @param {string} branch_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchGeneralMetricsRaw(requestParameters: GetBranchGeneralMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchGeneralMetrics>>;

    /**
     * Get general metrics of a branch
     */
    getBranchGeneralMetrics(requestParameters: GetBranchGeneralMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchGeneralMetrics>;

    /**
     * 
     * @summary Get net sales timeseries of a branch
     * @param {string} branch_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchNetSalesTimeseriesRaw(requestParameters: GetBranchNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchNetSalesTimeseriesResponse>>;

    /**
     * Get net sales timeseries of a branch
     */
    getBranchNetSalesTimeseries(requestParameters: GetBranchNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchNetSalesTimeseriesResponse>;

    /**
     * 
     * @summary Get net sales per order source
     * @param {string} branch_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchOrderSourcesNetSalesRaw(requestParameters: GetBranchOrderSourcesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchOrderSourcesNetSalesResponse>>;

    /**
     * Get net sales per order source
     */
    getBranchOrderSourcesNetSales(requestParameters: GetBranchOrderSourcesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchOrderSourcesNetSalesResponse>;

    /**
     * 
     * @summary Get net sales per order tag
     * @param {string} branch_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchOrderTagsNetSalesRaw(requestParameters: GetBranchOrderTagsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchOrderTagsNetSalesResponse>>;

    /**
     * Get net sales per order tag
     */
    getBranchOrderTagsNetSales(requestParameters: GetBranchOrderTagsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchOrderTagsNetSalesResponse>;

    /**
     * 
     * @summary Get net sales per order type
     * @param {string} branch_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchOrderTypesNetSalesRaw(requestParameters: GetBranchOrderTypesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchOrderTypesNetSalesResponse>>;

    /**
     * Get net sales per order type
     */
    getBranchOrderTypesNetSales(requestParameters: GetBranchOrderTypesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchOrderTypesNetSalesResponse>;

    /**
     * 
     * @summary Get net sales timeseries of user\'s branches
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchesNetSalesTimeseriesRaw(requestParameters: GetBranchesNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchesNetSalesTimeseriesResponse>>;

    /**
     * Get net sales timeseries of user\'s branches
     */
    getBranchesNetSalesTimeseries(requestParameters: GetBranchesNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchesNetSalesTimeseriesResponse>;

    /**
     * 
     * @summary Get report of user\'s branches by business
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchesReportRaw(requestParameters: GetBranchesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchesReportResponse>>;

    /**
     * Get report of user\'s branches by business
     */
    getBranchesReport(requestParameters: GetBranchesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchesReportResponse>;

    /**
     * 
     * @summary Get a budget
     * @param {string} business_id 
     * @param {'weekly' | 'monthly'} resolution 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBudgetRaw(requestParameters: GetBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BudgetResponse>>;

    /**
     * Get a budget
     */
    getBudget(requestParameters: GetBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BudgetResponse>;

    /**
     * 
     * @summary Get a budget template file
     * @param {string} business_id 
     * @param {'weekly' | 'monthly'} resolution 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBudgetTemplateRaw(requestParameters: GetBudgetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Get a budget template file
     */
    getBudgetTemplate(requestParameters: GetBudgetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @summary Get a budget in XLSX format
     * @param {string} business_id 
     * @param {'weekly' | 'monthly'} resolution 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBudgetXlsxRaw(requestParameters: GetBudgetXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Get a budget in XLSX format
     */
    getBudgetXlsx(requestParameters: GetBudgetXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @summary Get businesses of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBusinessesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBusinessesResponse>>;

    /**
     * Get businesses of user
     */
    getBusinesses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBusinessesResponse>;

    /**
     * This endpoint is used to install a new Foodics Business: create a new Norma Organization and Business
     * @summary Initiate Foodics OAuth2 flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getFoodicsAuthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * This endpoint is used to install a new Foodics Business: create a new Norma Organization and Business
     * Initiate Foodics OAuth2 flow
     */
    getFoodicsAuth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get impersonator data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getImpersonatorDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileImpersonatorResponse>>;

    /**
     * Get impersonator data
     */
    getImpersonatorData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileImpersonatorResponse>;

    /**
     * 
     * @summary Get inactive users by business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getInactiveUsersByBusinessRaw(requestParameters: GetInactiveUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInactiveFoodicsUsersResponse>>;

    /**
     * Get inactive users by business
     */
    getInactiveUsersByBusiness(requestParameters: GetInactiveUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInactiveFoodicsUsersResponse>;

    /**
     * 
     * @summary Get the foodics accounts that are eligible for the initial onboarding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getIntegrationsFoodicsAccountsInitialOnboardingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInitialOnboardingFoodicsAccountsResponse>>;

    /**
     * Get the foodics accounts that are eligible for the initial onboarding
     */
    getIntegrationsFoodicsAccountsInitialOnboarding(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInitialOnboardingFoodicsAccountsResponse>;

    /**
     * This endpoint is used to onboard a Foodics Business inside an existing Norma Organization
     * @summary Initiate Foodics OAuth2 flow for Business onboarding in existing Organization
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getIntegrationsFoodicsOnboardBusinessRaw(requestParameters: GetIntegrationsFoodicsOnboardBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * This endpoint is used to onboard a Foodics Business inside an existing Norma Organization
     * Initiate Foodics OAuth2 flow for Business onboarding in existing Organization
     */
    getIntegrationsFoodicsOnboardBusiness(requestParameters: GetIntegrationsFoodicsOnboardBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Mint a short lived token that allows onboarding a new business to an existing org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getIntegrationsFoodicsOnboardBusinessRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOnboardBusinessTokenResponse>>;

    /**
     * Mint a short lived token that allows onboarding a new business to an existing org
     */
    getIntegrationsFoodicsOnboardBusinessRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOnboardBusinessTokenResponse>;

    /**
     * 
     * @summary Get a branch\'s products with net sales and quantity
     * @param {string} branch_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {number} page 
     * @param {number} page_size 
     * @param {'net-sales' | 'quantity'} order_by 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBranchProductsWithNetSalesAndQuantityRaw(requestParameters: GetMenuMixBranchProductsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBranchProductsWithNetSalesAndQuantityListResponse>>;

    /**
     * Get a branch\'s products with net sales and quantity
     */
    getMenuMixBranchProductsWithNetSalesAndQuantity(requestParameters: GetMenuMixBranchProductsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBranchProductsWithNetSalesAndQuantityListResponse>;

    /**
     * 
     * @summary Get a business\' branches with quantity
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBusinessBranchesWithQuantityRaw(requestParameters: GetMenuMixBusinessBranchesWithQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessBranchesWithQuantityResponse>>;

    /**
     * Get a business\' branches with quantity
     */
    getMenuMixBusinessBranchesWithQuantity(requestParameters: GetMenuMixBusinessBranchesWithQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessBranchesWithQuantityResponse>;

    /**
     * 
     * @summary Get a business\' product branches with net sales and quantity
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {string} product_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBusinessProductBranchesWithNetSalesAndQuantityRaw(requestParameters: GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse>>;

    /**
     * Get a business\' product branches with net sales and quantity
     */
    getMenuMixBusinessProductBranchesWithNetSalesAndQuantity(requestParameters: GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse>;

    /**
     * 
     * @summary Get a business\' product categories with net sales
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {number} page 
     * @param {number} page_size 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBusinessProductCategoriesWithNetSalesRaw(requestParameters: GetMenuMixBusinessProductCategoriesWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductCategoriesWithNetSalesListResponse>>;

    /**
     * Get a business\' product categories with net sales
     */
    getMenuMixBusinessProductCategoriesWithNetSales(requestParameters: GetMenuMixBusinessProductCategoriesWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductCategoriesWithNetSalesListResponse>;

    /**
     * 
     * @summary Get a business\' product category branches with net sales
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {string} category_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBusinessProductCategoryBranchesWithNetSalesRaw(requestParameters: GetMenuMixBusinessProductCategoryBranchesWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductCategoryBranchesWithNetSalesResponse>>;

    /**
     * Get a business\' product category branches with net sales
     */
    getMenuMixBusinessProductCategoryBranchesWithNetSales(requestParameters: GetMenuMixBusinessProductCategoryBranchesWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductCategoryBranchesWithNetSalesResponse>;

    /**
     * 
     * @summary Get a business\' product category order tags with net sales
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {string} category_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBusinessProductCategoryOrderTagsWithNetSalesRaw(requestParameters: GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductCategoryOrderTagsWithNetSalesResponse>>;

    /**
     * Get a business\' product category order tags with net sales
     */
    getMenuMixBusinessProductCategoryOrderTagsWithNetSales(requestParameters: GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductCategoryOrderTagsWithNetSalesResponse>;

    /**
     * 
     * @summary Get a business\' product order tags with net sales and quantity
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {string} product_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityRaw(requestParameters: GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductOrderTagsWithNetSalesAndQuantityResponse>>;

    /**
     * Get a business\' product order tags with net sales and quantity
     */
    getMenuMixBusinessProductOrderTagsWithNetSalesAndQuantity(requestParameters: GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductOrderTagsWithNetSalesAndQuantityResponse>;

    /**
     * 
     * @summary Get timeseries of a business\' products quantity sold
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBusinessProductsQuantityTimeseriesRaw(requestParameters: GetMenuMixBusinessProductsQuantityTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductsQuantityTimeseriesResponse>>;

    /**
     * Get timeseries of a business\' products quantity sold
     */
    getMenuMixBusinessProductsQuantityTimeseries(requestParameters: GetMenuMixBusinessProductsQuantityTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductsQuantityTimeseriesResponse>;

    /**
     * 
     * @summary Get a business\' products with net sales and quantity
     * @param {string} business_id 
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period 
     * @param {number} page 
     * @param {number} page_size 
     * @param {'net-sales' | 'quantity'} order_by 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuMixBusinessProductsWithNetSalesAndQuantityRaw(requestParameters: GetMenuMixBusinessProductsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductsWithNetSalesAndQuantityListResponse>>;

    /**
     * Get a business\' products with net sales and quantity
     */
    getMenuMixBusinessProductsWithNetSalesAndQuantity(requestParameters: GetMenuMixBusinessProductsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductsWithNetSalesAndQuantityListResponse>;

    /**
     * 
     * @summary Get the users of the logged in user\'s organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getOrganizationUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersByOrganizationResponse>>;

    /**
     * Get the users of the logged in user\'s organization
     */
    getOrganizationUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersByOrganizationResponse>;

    /**
     * 
     * @summary Get cumulative info for the account of a business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProfileBusinessAccountCumulativeInfoRaw(requestParameters: GetProfileBusinessAccountCumulativeInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountCumulativeInfo>>;

    /**
     * Get cumulative info for the account of a business
     */
    getProfileBusinessAccountCumulativeInfo(requestParameters: GetProfileBusinessAccountCumulativeInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountCumulativeInfo>;

    /**
     * 
     * @summary Get customer success info of a business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProfileBusinessCustomerSuccessRaw(requestParameters: GetProfileBusinessCustomerSuccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerSuccessInfo>>;

    /**
     * Get customer success info of a business
     */
    getProfileBusinessCustomerSuccess(requestParameters: GetProfileBusinessCustomerSuccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerSuccessInfo>;

    /**
     * 
     * @summary Get general info for a business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProfileBusinessInfoRaw(requestParameters: GetProfileBusinessInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessInfo>>;

    /**
     * Get general info for a business
     */
    getProfileBusinessInfo(requestParameters: GetProfileBusinessInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessInfo>;

    /**
     * 
     * @summary Get the invoices of a business
     * @param {string} business_id 
     * @param {number} page 
     * @param {number} page_size 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProfileBusinessInvoicesRaw(requestParameters: GetProfileBusinessInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileInvoicesResponse>>;

    /**
     * Get the invoices of a business
     */
    getProfileBusinessInvoices(requestParameters: GetProfileBusinessInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileInvoicesResponse>;

    /**
     * 
     * @summary Get cumulative info about invoices for a business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProfileBusinessInvoicesCumulativeInfoRaw(requestParameters: GetProfileBusinessInvoicesCumulativeInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesCumulativeInfo>>;

    /**
     * Get cumulative info about invoices for a business
     */
    getProfileBusinessInvoicesCumulativeInfo(requestParameters: GetProfileBusinessInvoicesCumulativeInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesCumulativeInfo>;

    /**
     * 
     * @summary Get the licenses of a business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProfileBusinessLicensesRaw(requestParameters: GetProfileBusinessLicensesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Licenses>>;

    /**
     * Get the licenses of a business
     */
    getProfileBusinessLicenses(requestParameters: GetProfileBusinessLicensesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Licenses>;

    /**
     * 
     * @summary Get support tickets of a business
     * @param {string} business_id 
     * @param {number} page 
     * @param {number} page_size 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProfileBusinessSupportTicketsRaw(requestParameters: GetProfileBusinessSupportTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileSupportTicketsResponse>>;

    /**
     * Get support tickets of a business
     */
    getProfileBusinessSupportTickets(requestParameters: GetProfileBusinessSupportTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileSupportTicketsResponse>;

    /**
     * 
     * @summary Refresh auth
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getRefreshRaw(requestParameters: GetRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>>;

    /**
     * Refresh auth
     */
    getRefresh(requestParameters: GetRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token>;

    /**
     * Update self
     * @summary Self endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getSelfRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAggregate>>;

    /**
     * Update self
     * Self endpoint
     */
    getSelf(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAggregate>;

    /**
     * 
     * @summary Get the currency of a business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getSettingsCurrencyRaw(requestParameters: GetSettingsCurrencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Currency>>;

    /**
     * Get the currency of a business
     */
    getSettingsCurrency(requestParameters: GetSettingsCurrencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Currency>;

    /**
     * 
     * @summary Get the timezone of a business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getSettingsTimezoneRaw(requestParameters: GetSettingsTimezoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Timezone>>;

    /**
     * Get the timezone of a business
     */
    getSettingsTimezone(requestParameters: GetSettingsTimezoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Timezone>;

    /**
     * 
     * @summary Returns information about the status of the biserver service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusResponse>>;

    /**
     * Returns information about the status of the biserver service
     */
    getStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusResponse>;

    /**
     * 
     * @summary Get thoughtspot liveboards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getThoughtspotLiveboardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotLiveboardsResponse>>;

    /**
     * Get thoughtspot liveboards
     */
    getThoughtspotLiveboards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotLiveboardsResponse>;

    /**
     * 
     * @summary Get thoughtspot schedules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getThoughtspotSchedulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotSchedulesResponse>>;

    /**
     * Get thoughtspot schedules
     */
    getThoughtspotSchedules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotSchedulesResponse>;

    /**
     * 
     * @summary Get thoughtspot data sources for the search data feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getThoughtspotSearchDataDataSourcesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotDataSourcesResponse>>;

    /**
     * Get thoughtspot data sources for the search data feature
     */
    getThoughtspotSearchDataDataSources(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotDataSourcesResponse>;

    /**
     * 
     * @summary Get thoughtspot token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getThoughtspotTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThoughtspotToken>>;

    /**
     * Get thoughtspot token
     */
    getThoughtspotToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThoughtspotToken>;

    /**
     * 
     * @summary Get users by business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getUsersByBusinessRaw(requestParameters: GetUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersByBusinessResponse>>;

    /**
     * Get users by business
     */
    getUsersByBusiness(requestParameters: GetUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersByBusinessResponse>;

    /**
     * 
     * @summary Impersonate another user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    impersonateRaw(requestParameters: ImpersonateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImpersonateResponse>>;

    /**
     * Impersonate another user
     */
    impersonate(requestParameters: ImpersonateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImpersonateResponse>;

    /**
     * 
     * @summary Update business
     * @param {string} business_id 
     * @param {UpdateBusinessRequest} [UpdateBusinessRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    patchBusinessesBusinessIdRaw(requestParameters: PatchBusinessesBusinessIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Business>>;

    /**
     * Update business
     */
    patchBusinessesBusinessId(requestParameters: PatchBusinessesBusinessIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Business>;

    /**
     * 
     * @summary Upload a budget file
     * @param {string} business_id 
     * @param {number} year 
     * @param {Blob} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postBudgetRaw(requestParameters: PostBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Upload a budget file
     */
    postBudget(requestParameters: PostBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Change password
     * @param {ChangePasswordRequest} [ChangePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postChangePasswordRaw(requestParameters: PostChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Change password
     */
    postChangePassword(requestParameters: PostChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Create the respective Norma accounts for the selected foodics accounts
     * @param {InitialOnboardFoodicsUsersRequest} [InitialOnboardFoodicsUsersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postIntegrationsFoodicsAccountsInitialOnboardingRaw(requestParameters: PostIntegrationsFoodicsAccountsInitialOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Create the respective Norma accounts for the selected foodics accounts
     */
    postIntegrationsFoodicsAccountsInitialOnboarding(requestParameters: PostIntegrationsFoodicsAccountsInitialOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Invite Foodics accounts
     * @param {InviteFoodicsUsersRequest} [InviteFoodicsUsersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postInviteFoodicsAccountsRaw(requestParameters: PostInviteFoodicsAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Invite Foodics accounts
     */
    postInviteFoodicsAccounts(requestParameters: PostInviteFoodicsAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {LoginRequest} [LoginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postLoginRaw(requestParameters: PostLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>>;

    /**
     */
    postLogin(requestParameters: PostLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    postLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Onboard users in business
     * @param {string} business_id 
     * @param {OnboardFoodicsUsersRequest} [OnboardFoodicsUsersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postOnboardUsersToBusinessRaw(requestParameters: PostOnboardUsersToBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Onboard users in business
     */
    postOnboardUsersToBusiness(requestParameters: PostOnboardUsersToBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {AdminLoginRequest} [AdminLoginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postOpsLoginRaw(requestParameters: PostOpsLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>>;

    /**
     */
    postOpsLogin(requestParameters: PostOpsLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse>;

    /**
     * 
     * @summary Request reset password
     * @param {RequestResetPasswordRequest} [RequestResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postRequestResetPasswordRaw(requestParameters: PostRequestResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RequestResetPasswordResponse>>;

    /**
     * Request reset password
     */
    postRequestResetPassword(requestParameters: PostRequestResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RequestResetPasswordResponse>;

    /**
     * 
     * @summary Reset password
     * @param {ResetPasswordRequest} [ResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postResetPasswordRaw(requestParameters: PostResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>>;

    /**
     * Reset password
     */
    postResetPassword(requestParameters: PostResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse>;

    /**
     * 
     * @summary Change business context
     * @param {ChangeBusinessContextRequest} [ChangeBusinessContextRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postSelfBusinessContextRaw(requestParameters: PostSelfBusinessContextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Change business context
     */
    postSelfBusinessContext(requestParameters: PostSelfBusinessContextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Change enterprise web app liveboards order
     * @param {Array<LiveboardCategory>} [LiveboardCategory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postSelfEnterpriseWebAppLiveboardsOrderRaw(requestParameters: PostSelfEnterpriseWebAppLiveboardsOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Change enterprise web app liveboards order
     */
    postSelfEnterpriseWebAppLiveboardsOrder(requestParameters: PostSelfEnterpriseWebAppLiveboardsOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Change enterprise web app mode
     * @param {ChangeModeRequest} [ChangeModeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postSelfEnterpriseWebAppModeRaw(requestParameters: PostSelfEnterpriseWebAppModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Change enterprise web app mode
     */
    postSelfEnterpriseWebAppMode(requestParameters: PostSelfEnterpriseWebAppModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Create a new liveboard
     * @param {string} liveboard_name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postThoughtspotLiveboardsCreateRaw(requestParameters: PostThoughtspotLiveboardsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThoughtspotLiveboardsCreateResponse>>;

    /**
     * Create a new liveboard
     */
    postThoughtspotLiveboardsCreate(requestParameters: PostThoughtspotLiveboardsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThoughtspotLiveboardsCreateResponse>;

    /**
     * 
     * @summary Unfavorite thoughtspot liveboard
     * @param {string} liveboard_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    unfavoriteThoughtspotLiveboardRaw(requestParameters: UnfavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Unfavorite thoughtspot liveboard
     */
    unfavoriteThoughtspotLiveboard(requestParameters: UnfavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Promote user to admin
     * @param {string} user_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersPromoteUserRaw(requestParameters: UsersPromoteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Promote user to admin
     */
    usersPromoteUser(requestParameters: UsersPromoteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {

    /**
     * Delete user
     */
    async deleteUsersUserIdRaw(requestParameters: DeleteUsersUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['user_id'] == null) {
            throw new runtime.RequiredError(
                'user_id',
                'Required parameter "user_id" was null or undefined when calling deleteUsersUserId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['user_id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete user
     */
    async deleteUsersUserId(requestParameters: DeleteUsersUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUsersUserIdRaw(requestParameters, initOverrides);
    }

    /**
     * Favorite thoughtspot liveboard to favorites
     */
    async favoriteThoughtspotLiveboardRaw(requestParameters: FavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['liveboard_id'] == null) {
            throw new runtime.RequiredError(
                'liveboard_id',
                'Required parameter "liveboard_id" was null or undefined when calling favoriteThoughtspotLiveboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['liveboard_id'] != null) {
            queryParameters['liveboard_id'] = requestParameters['liveboard_id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/liveboards/favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Favorite thoughtspot liveboard to favorites
     */
    async favoriteThoughtspotLiveboard(requestParameters: FavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.favoriteThoughtspotLiveboardRaw(requestParameters, initOverrides);
    }

    /**
     * Get net sales per app
     */
    async getBranchAppsNetSalesRaw(requestParameters: GetBranchAppsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchAppsNetSalesResponse>> {
        if (requestParameters['branch_id'] == null) {
            throw new runtime.RequiredError(
                'branch_id',
                'Required parameter "branch_id" was null or undefined when calling getBranchAppsNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchAppsNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/branches-report/branches/{branch_id}/apps/net-sales`.replace(`{${"branch_id"}}`, encodeURIComponent(String(requestParameters['branch_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchAppsNetSalesResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales per app
     */
    async getBranchAppsNetSales(requestParameters: GetBranchAppsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchAppsNetSalesResponse> {
        const response = await this.getBranchAppsNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get general metrics of a branch
     */
    async getBranchGeneralMetricsRaw(requestParameters: GetBranchGeneralMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchGeneralMetrics>> {
        if (requestParameters['branch_id'] == null) {
            throw new runtime.RequiredError(
                'branch_id',
                'Required parameter "branch_id" was null or undefined when calling getBranchGeneralMetrics().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchGeneralMetrics().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/branches-report/branches/{branch_id}/general-metrics`.replace(`{${"branch_id"}}`, encodeURIComponent(String(requestParameters['branch_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchGeneralMetricsFromJSON(jsonValue));
    }

    /**
     * Get general metrics of a branch
     */
    async getBranchGeneralMetrics(requestParameters: GetBranchGeneralMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchGeneralMetrics> {
        const response = await this.getBranchGeneralMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales timeseries of a branch
     */
    async getBranchNetSalesTimeseriesRaw(requestParameters: GetBranchNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchNetSalesTimeseriesResponse>> {
        if (requestParameters['branch_id'] == null) {
            throw new runtime.RequiredError(
                'branch_id',
                'Required parameter "branch_id" was null or undefined when calling getBranchNetSalesTimeseries().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchNetSalesTimeseries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/branches-report/branches/{branch_id}/timeseries/net-sales`.replace(`{${"branch_id"}}`, encodeURIComponent(String(requestParameters['branch_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchNetSalesTimeseriesResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales timeseries of a branch
     */
    async getBranchNetSalesTimeseries(requestParameters: GetBranchNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchNetSalesTimeseriesResponse> {
        const response = await this.getBranchNetSalesTimeseriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales per order source
     */
    async getBranchOrderSourcesNetSalesRaw(requestParameters: GetBranchOrderSourcesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchOrderSourcesNetSalesResponse>> {
        if (requestParameters['branch_id'] == null) {
            throw new runtime.RequiredError(
                'branch_id',
                'Required parameter "branch_id" was null or undefined when calling getBranchOrderSourcesNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchOrderSourcesNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/branches-report/branches/{branch_id}/order-sources/net-sales`.replace(`{${"branch_id"}}`, encodeURIComponent(String(requestParameters['branch_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchOrderSourcesNetSalesResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales per order source
     */
    async getBranchOrderSourcesNetSales(requestParameters: GetBranchOrderSourcesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchOrderSourcesNetSalesResponse> {
        const response = await this.getBranchOrderSourcesNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales per order tag
     */
    async getBranchOrderTagsNetSalesRaw(requestParameters: GetBranchOrderTagsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchOrderTagsNetSalesResponse>> {
        if (requestParameters['branch_id'] == null) {
            throw new runtime.RequiredError(
                'branch_id',
                'Required parameter "branch_id" was null or undefined when calling getBranchOrderTagsNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchOrderTagsNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/branches-report/branches/{branch_id}/order-tags/net-sales`.replace(`{${"branch_id"}}`, encodeURIComponent(String(requestParameters['branch_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchOrderTagsNetSalesResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales per order tag
     */
    async getBranchOrderTagsNetSales(requestParameters: GetBranchOrderTagsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchOrderTagsNetSalesResponse> {
        const response = await this.getBranchOrderTagsNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales per order type
     */
    async getBranchOrderTypesNetSalesRaw(requestParameters: GetBranchOrderTypesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchOrderTypesNetSalesResponse>> {
        if (requestParameters['branch_id'] == null) {
            throw new runtime.RequiredError(
                'branch_id',
                'Required parameter "branch_id" was null or undefined when calling getBranchOrderTypesNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchOrderTypesNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/branches-report/branches/{branch_id}/order-types/net-sales`.replace(`{${"branch_id"}}`, encodeURIComponent(String(requestParameters['branch_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchOrderTypesNetSalesResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales per order type
     */
    async getBranchOrderTypesNetSales(requestParameters: GetBranchOrderTypesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchOrderTypesNetSalesResponse> {
        const response = await this.getBranchOrderTypesNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales timeseries of user\'s branches
     */
    async getBranchesNetSalesTimeseriesRaw(requestParameters: GetBranchesNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchesNetSalesTimeseriesResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getBranchesNetSalesTimeseries().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchesNetSalesTimeseries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/branches-report/businesses/{business_id}/timeseries/net-sales`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchesNetSalesTimeseriesResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales timeseries of user\'s branches
     */
    async getBranchesNetSalesTimeseries(requestParameters: GetBranchesNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchesNetSalesTimeseriesResponse> {
        const response = await this.getBranchesNetSalesTimeseriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get report of user\'s branches by business
     */
    async getBranchesReportRaw(requestParameters: GetBranchesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BranchesReportResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getBranchesReport().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchesReport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/branches-report/businesses/{business_id}`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchesReportResponseFromJSON(jsonValue));
    }

    /**
     * Get report of user\'s branches by business
     */
    async getBranchesReport(requestParameters: GetBranchesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BranchesReportResponse> {
        const response = await this.getBranchesReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a budget
     */
    async getBudgetRaw(requestParameters: GetBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BudgetResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getBudget().'
            );
        }

        if (requestParameters['resolution'] == null) {
            throw new runtime.RequiredError(
                'resolution',
                'Required parameter "resolution" was null or undefined when calling getBudget().'
            );
        }

        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling getBudget().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['resolution'] != null) {
            queryParameters['resolution'] = requestParameters['resolution'];
        }

        if (requestParameters['year'] != null) {
            queryParameters['year'] = requestParameters['year'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{business_id}`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BudgetResponseFromJSON(jsonValue));
    }

    /**
     * Get a budget
     */
    async getBudget(requestParameters: GetBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BudgetResponse> {
        const response = await this.getBudgetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a budget template file
     */
    async getBudgetTemplateRaw(requestParameters: GetBudgetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getBudgetTemplate().'
            );
        }

        if (requestParameters['resolution'] == null) {
            throw new runtime.RequiredError(
                'resolution',
                'Required parameter "resolution" was null or undefined when calling getBudgetTemplate().'
            );
        }

        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling getBudgetTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['resolution'] != null) {
            queryParameters['resolution'] = requestParameters['resolution'];
        }

        if (requestParameters['year'] != null) {
            queryParameters['year'] = requestParameters['year'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{business_id}/template`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get a budget template file
     */
    async getBudgetTemplate(requestParameters: GetBudgetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getBudgetTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a budget in XLSX format
     */
    async getBudgetXlsxRaw(requestParameters: GetBudgetXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getBudgetXlsx().'
            );
        }

        if (requestParameters['resolution'] == null) {
            throw new runtime.RequiredError(
                'resolution',
                'Required parameter "resolution" was null or undefined when calling getBudgetXlsx().'
            );
        }

        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling getBudgetXlsx().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['resolution'] != null) {
            queryParameters['resolution'] = requestParameters['resolution'];
        }

        if (requestParameters['year'] != null) {
            queryParameters['year'] = requestParameters['year'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{business_id}/xlsx`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get a budget in XLSX format
     */
    async getBudgetXlsx(requestParameters: GetBudgetXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getBudgetXlsxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get businesses of user
     */
    async getBusinessesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBusinessesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBusinessesResponseFromJSON(jsonValue));
    }

    /**
     * Get businesses of user
     */
    async getBusinesses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBusinessesResponse> {
        const response = await this.getBusinessesRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint is used to install a new Foodics Business: create a new Norma Organization and Business
     * Initiate Foodics OAuth2 flow
     */
    async getFoodicsAuthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/foodics-auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint is used to install a new Foodics Business: create a new Norma Organization and Business
     * Initiate Foodics OAuth2 flow
     */
    async getFoodicsAuth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getFoodicsAuthRaw(initOverrides);
    }

    /**
     * Get impersonator data
     */
    async getImpersonatorDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileImpersonatorResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ops/mobile/impersonator`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileImpersonatorResponseFromJSON(jsonValue));
    }

    /**
     * Get impersonator data
     */
    async getImpersonatorData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileImpersonatorResponse> {
        const response = await this.getImpersonatorDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get inactive users by business
     */
    async getInactiveUsersByBusinessRaw(requestParameters: GetInactiveUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInactiveFoodicsUsersResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getInactiveUsersByBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/{business_id}/accounts/inactive`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInactiveFoodicsUsersResponseFromJSON(jsonValue));
    }

    /**
     * Get inactive users by business
     */
    async getInactiveUsersByBusiness(requestParameters: GetInactiveUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInactiveFoodicsUsersResponse> {
        const response = await this.getInactiveUsersByBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the foodics accounts that are eligible for the initial onboarding
     */
    async getIntegrationsFoodicsAccountsInitialOnboardingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInitialOnboardingFoodicsAccountsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Foodics_Initial_Onboarding", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/accounts/initial-onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInitialOnboardingFoodicsAccountsResponseFromJSON(jsonValue));
    }

    /**
     * Get the foodics accounts that are eligible for the initial onboarding
     */
    async getIntegrationsFoodicsAccountsInitialOnboarding(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInitialOnboardingFoodicsAccountsResponse> {
        const response = await this.getIntegrationsFoodicsAccountsInitialOnboardingRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint is used to onboard a Foodics Business inside an existing Norma Organization
     * Initiate Foodics OAuth2 flow for Business onboarding in existing Organization
     */
    async getIntegrationsFoodicsOnboardBusinessRaw(requestParameters: GetIntegrationsFoodicsOnboardBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling getIntegrationsFoodicsOnboardBusiness().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/onboard-business`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint is used to onboard a Foodics Business inside an existing Norma Organization
     * Initiate Foodics OAuth2 flow for Business onboarding in existing Organization
     */
    async getIntegrationsFoodicsOnboardBusiness(requestParameters: GetIntegrationsFoodicsOnboardBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getIntegrationsFoodicsOnboardBusinessRaw(requestParameters, initOverrides);
    }

    /**
     * Mint a short lived token that allows onboarding a new business to an existing org
     */
    async getIntegrationsFoodicsOnboardBusinessRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOnboardBusinessTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/onboard-business-request`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOnboardBusinessTokenResponseFromJSON(jsonValue));
    }

    /**
     * Mint a short lived token that allows onboarding a new business to an existing org
     */
    async getIntegrationsFoodicsOnboardBusinessRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOnboardBusinessTokenResponse> {
        const response = await this.getIntegrationsFoodicsOnboardBusinessRequestRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a branch\'s products with net sales and quantity
     */
    async getMenuMixBranchProductsWithNetSalesAndQuantityRaw(requestParameters: GetMenuMixBranchProductsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBranchProductsWithNetSalesAndQuantityListResponse>> {
        if (requestParameters['branch_id'] == null) {
            throw new runtime.RequiredError(
                'branch_id',
                'Required parameter "branch_id" was null or undefined when calling getMenuMixBranchProductsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBranchProductsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getMenuMixBranchProductsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['page_size'] == null) {
            throw new runtime.RequiredError(
                'page_size',
                'Required parameter "page_size" was null or undefined when calling getMenuMixBranchProductsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['order_by'] == null) {
            throw new runtime.RequiredError(
                'order_by',
                'Required parameter "order_by" was null or undefined when calling getMenuMixBranchProductsWithNetSalesAndQuantity().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['page_size'] != null) {
            queryParameters['page-size'] = requestParameters['page_size'];
        }

        if (requestParameters['order_by'] != null) {
            queryParameters['order-by'] = requestParameters['order_by'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/branch/{branch_id}/products/with-net-sales-and-quantity`.replace(`{${"branch_id"}}`, encodeURIComponent(String(requestParameters['branch_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBranchProductsWithNetSalesAndQuantityListResponseFromJSON(jsonValue));
    }

    /**
     * Get a branch\'s products with net sales and quantity
     */
    async getMenuMixBranchProductsWithNetSalesAndQuantity(requestParameters: GetMenuMixBranchProductsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBranchProductsWithNetSalesAndQuantityListResponse> {
        const response = await this.getMenuMixBranchProductsWithNetSalesAndQuantityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a business\' branches with quantity
     */
    async getMenuMixBusinessBranchesWithQuantityRaw(requestParameters: GetMenuMixBusinessBranchesWithQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessBranchesWithQuantityResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getMenuMixBusinessBranchesWithQuantity().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBusinessBranchesWithQuantity().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/business/{business_id}/branches/with-quantity`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBusinessBranchesWithQuantityResponseFromJSON(jsonValue));
    }

    /**
     * Get a business\' branches with quantity
     */
    async getMenuMixBusinessBranchesWithQuantity(requestParameters: GetMenuMixBusinessBranchesWithQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessBranchesWithQuantityResponse> {
        const response = await this.getMenuMixBusinessBranchesWithQuantityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a business\' product branches with net sales and quantity
     */
    async getMenuMixBusinessProductBranchesWithNetSalesAndQuantityRaw(requestParameters: GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getMenuMixBusinessProductBranchesWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBusinessProductBranchesWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['product_id'] == null) {
            throw new runtime.RequiredError(
                'product_id',
                'Required parameter "product_id" was null or undefined when calling getMenuMixBusinessProductBranchesWithNetSalesAndQuantity().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/business/{business_id}/product/{product_id}/branches/with-net-sales-and-quantity`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))).replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters['product_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseFromJSON(jsonValue));
    }

    /**
     * Get a business\' product branches with net sales and quantity
     */
    async getMenuMixBusinessProductBranchesWithNetSalesAndQuantity(requestParameters: GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse> {
        const response = await this.getMenuMixBusinessProductBranchesWithNetSalesAndQuantityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a business\' product categories with net sales
     */
    async getMenuMixBusinessProductCategoriesWithNetSalesRaw(requestParameters: GetMenuMixBusinessProductCategoriesWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductCategoriesWithNetSalesListResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getMenuMixBusinessProductCategoriesWithNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBusinessProductCategoriesWithNetSales().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getMenuMixBusinessProductCategoriesWithNetSales().'
            );
        }

        if (requestParameters['page_size'] == null) {
            throw new runtime.RequiredError(
                'page_size',
                'Required parameter "page_size" was null or undefined when calling getMenuMixBusinessProductCategoriesWithNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['page_size'] != null) {
            queryParameters['page-size'] = requestParameters['page_size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/business/{business_id}/product-categories/with-net-sales`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBusinessProductCategoriesWithNetSalesListResponseFromJSON(jsonValue));
    }

    /**
     * Get a business\' product categories with net sales
     */
    async getMenuMixBusinessProductCategoriesWithNetSales(requestParameters: GetMenuMixBusinessProductCategoriesWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductCategoriesWithNetSalesListResponse> {
        const response = await this.getMenuMixBusinessProductCategoriesWithNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a business\' product category branches with net sales
     */
    async getMenuMixBusinessProductCategoryBranchesWithNetSalesRaw(requestParameters: GetMenuMixBusinessProductCategoryBranchesWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductCategoryBranchesWithNetSalesResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getMenuMixBusinessProductCategoryBranchesWithNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBusinessProductCategoryBranchesWithNetSales().'
            );
        }

        if (requestParameters['category_id'] == null) {
            throw new runtime.RequiredError(
                'category_id',
                'Required parameter "category_id" was null or undefined when calling getMenuMixBusinessProductCategoryBranchesWithNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/business/{business_id}/product-category/{category_id}/branches/with-net-sales`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters['category_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBusinessProductCategoryBranchesWithNetSalesResponseFromJSON(jsonValue));
    }

    /**
     * Get a business\' product category branches with net sales
     */
    async getMenuMixBusinessProductCategoryBranchesWithNetSales(requestParameters: GetMenuMixBusinessProductCategoryBranchesWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductCategoryBranchesWithNetSalesResponse> {
        const response = await this.getMenuMixBusinessProductCategoryBranchesWithNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a business\' product category order tags with net sales
     */
    async getMenuMixBusinessProductCategoryOrderTagsWithNetSalesRaw(requestParameters: GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductCategoryOrderTagsWithNetSalesResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getMenuMixBusinessProductCategoryOrderTagsWithNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBusinessProductCategoryOrderTagsWithNetSales().'
            );
        }

        if (requestParameters['category_id'] == null) {
            throw new runtime.RequiredError(
                'category_id',
                'Required parameter "category_id" was null or undefined when calling getMenuMixBusinessProductCategoryOrderTagsWithNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/business/{business_id}/product-category/{category_id}/order-tags/with-net-sales`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters['category_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBusinessProductCategoryOrderTagsWithNetSalesResponseFromJSON(jsonValue));
    }

    /**
     * Get a business\' product category order tags with net sales
     */
    async getMenuMixBusinessProductCategoryOrderTagsWithNetSales(requestParameters: GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductCategoryOrderTagsWithNetSalesResponse> {
        const response = await this.getMenuMixBusinessProductCategoryOrderTagsWithNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a business\' product order tags with net sales and quantity
     */
    async getMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityRaw(requestParameters: GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductOrderTagsWithNetSalesAndQuantityResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getMenuMixBusinessProductOrderTagsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBusinessProductOrderTagsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['product_id'] == null) {
            throw new runtime.RequiredError(
                'product_id',
                'Required parameter "product_id" was null or undefined when calling getMenuMixBusinessProductOrderTagsWithNetSalesAndQuantity().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/business/{business_id}/product/{product_id}/order-tags/with-net-sales-and-quantity`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))).replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters['product_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBusinessProductOrderTagsWithNetSalesAndQuantityResponseFromJSON(jsonValue));
    }

    /**
     * Get a business\' product order tags with net sales and quantity
     */
    async getMenuMixBusinessProductOrderTagsWithNetSalesAndQuantity(requestParameters: GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductOrderTagsWithNetSalesAndQuantityResponse> {
        const response = await this.getMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get timeseries of a business\' products quantity sold
     */
    async getMenuMixBusinessProductsQuantityTimeseriesRaw(requestParameters: GetMenuMixBusinessProductsQuantityTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductsQuantityTimeseriesResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getMenuMixBusinessProductsQuantityTimeseries().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBusinessProductsQuantityTimeseries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/business/{business_id}/products-quantity-timeseries`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBusinessProductsQuantityTimeseriesResponseFromJSON(jsonValue));
    }

    /**
     * Get timeseries of a business\' products quantity sold
     */
    async getMenuMixBusinessProductsQuantityTimeseries(requestParameters: GetMenuMixBusinessProductsQuantityTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductsQuantityTimeseriesResponse> {
        const response = await this.getMenuMixBusinessProductsQuantityTimeseriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a business\' products with net sales and quantity
     */
    async getMenuMixBusinessProductsWithNetSalesAndQuantityRaw(requestParameters: GetMenuMixBusinessProductsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuMixBusinessProductsWithNetSalesAndQuantityListResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getMenuMixBusinessProductsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getMenuMixBusinessProductsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getMenuMixBusinessProductsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['page_size'] == null) {
            throw new runtime.RequiredError(
                'page_size',
                'Required parameter "page_size" was null or undefined when calling getMenuMixBusinessProductsWithNetSalesAndQuantity().'
            );
        }

        if (requestParameters['order_by'] == null) {
            throw new runtime.RequiredError(
                'order_by',
                'Required parameter "order_by" was null or undefined when calling getMenuMixBusinessProductsWithNetSalesAndQuantity().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['page_size'] != null) {
            queryParameters['page-size'] = requestParameters['page_size'];
        }

        if (requestParameters['order_by'] != null) {
            queryParameters['order-by'] = requestParameters['order_by'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/menu-mix/business/{business_id}/products/with-net-sales-and-quantity`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuMixBusinessProductsWithNetSalesAndQuantityListResponseFromJSON(jsonValue));
    }

    /**
     * Get a business\' products with net sales and quantity
     */
    async getMenuMixBusinessProductsWithNetSalesAndQuantity(requestParameters: GetMenuMixBusinessProductsWithNetSalesAndQuantityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuMixBusinessProductsWithNetSalesAndQuantityListResponse> {
        const response = await this.getMenuMixBusinessProductsWithNetSalesAndQuantityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the users of the logged in user\'s organization
     */
    async getOrganizationUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersByOrganizationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersByOrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Get the users of the logged in user\'s organization
     */
    async getOrganizationUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersByOrganizationResponse> {
        const response = await this.getOrganizationUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get cumulative info for the account of a business
     */
    async getProfileBusinessAccountCumulativeInfoRaw(requestParameters: GetProfileBusinessAccountCumulativeInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountCumulativeInfo>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getProfileBusinessAccountCumulativeInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/profile/business/{business_id}/account-cumulative-info`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountCumulativeInfoFromJSON(jsonValue));
    }

    /**
     * Get cumulative info for the account of a business
     */
    async getProfileBusinessAccountCumulativeInfo(requestParameters: GetProfileBusinessAccountCumulativeInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountCumulativeInfo> {
        const response = await this.getProfileBusinessAccountCumulativeInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer success info of a business
     */
    async getProfileBusinessCustomerSuccessRaw(requestParameters: GetProfileBusinessCustomerSuccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerSuccessInfo>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getProfileBusinessCustomerSuccess().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/profile/business/{business_id}/customer-success`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerSuccessInfoFromJSON(jsonValue));
    }

    /**
     * Get customer success info of a business
     */
    async getProfileBusinessCustomerSuccess(requestParameters: GetProfileBusinessCustomerSuccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerSuccessInfo> {
        const response = await this.getProfileBusinessCustomerSuccessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get general info for a business
     */
    async getProfileBusinessInfoRaw(requestParameters: GetProfileBusinessInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessInfo>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getProfileBusinessInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/profile/business/{business_id}/info`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessInfoFromJSON(jsonValue));
    }

    /**
     * Get general info for a business
     */
    async getProfileBusinessInfo(requestParameters: GetProfileBusinessInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessInfo> {
        const response = await this.getProfileBusinessInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the invoices of a business
     */
    async getProfileBusinessInvoicesRaw(requestParameters: GetProfileBusinessInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileInvoicesResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getProfileBusinessInvoices().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getProfileBusinessInvoices().'
            );
        }

        if (requestParameters['page_size'] == null) {
            throw new runtime.RequiredError(
                'page_size',
                'Required parameter "page_size" was null or undefined when calling getProfileBusinessInvoices().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['page_size'] != null) {
            queryParameters['page-size'] = requestParameters['page_size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/profile/business/{business_id}/invoices`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileInvoicesResponseFromJSON(jsonValue));
    }

    /**
     * Get the invoices of a business
     */
    async getProfileBusinessInvoices(requestParameters: GetProfileBusinessInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileInvoicesResponse> {
        const response = await this.getProfileBusinessInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get cumulative info about invoices for a business
     */
    async getProfileBusinessInvoicesCumulativeInfoRaw(requestParameters: GetProfileBusinessInvoicesCumulativeInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesCumulativeInfo>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getProfileBusinessInvoicesCumulativeInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/profile/business/{business_id}/invoices/cumulative-info`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicesCumulativeInfoFromJSON(jsonValue));
    }

    /**
     * Get cumulative info about invoices for a business
     */
    async getProfileBusinessInvoicesCumulativeInfo(requestParameters: GetProfileBusinessInvoicesCumulativeInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesCumulativeInfo> {
        const response = await this.getProfileBusinessInvoicesCumulativeInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the licenses of a business
     */
    async getProfileBusinessLicensesRaw(requestParameters: GetProfileBusinessLicensesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Licenses>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getProfileBusinessLicenses().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/profile/business/{business_id}/licenses`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LicensesFromJSON(jsonValue));
    }

    /**
     * Get the licenses of a business
     */
    async getProfileBusinessLicenses(requestParameters: GetProfileBusinessLicensesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Licenses> {
        const response = await this.getProfileBusinessLicensesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get support tickets of a business
     */
    async getProfileBusinessSupportTicketsRaw(requestParameters: GetProfileBusinessSupportTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileSupportTicketsResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getProfileBusinessSupportTickets().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getProfileBusinessSupportTickets().'
            );
        }

        if (requestParameters['page_size'] == null) {
            throw new runtime.RequiredError(
                'page_size',
                'Required parameter "page_size" was null or undefined when calling getProfileBusinessSupportTickets().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['page_size'] != null) {
            queryParameters['page-size'] = requestParameters['page_size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/profile/business/{business_id}/support-tickets`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileSupportTicketsResponseFromJSON(jsonValue));
    }

    /**
     * Get support tickets of a business
     */
    async getProfileBusinessSupportTickets(requestParameters: GetProfileBusinessSupportTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileSupportTicketsResponse> {
        const response = await this.getProfileBusinessSupportTicketsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Refresh auth
     */
    async getRefreshRaw(requestParameters: GetRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling getRefresh().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/refresh`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Refresh auth
     */
    async getRefresh(requestParameters: GetRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.getRefreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update self
     * Self endpoint
     */
    async getSelfRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAggregate>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAggregateFromJSON(jsonValue));
    }

    /**
     * Update self
     * Self endpoint
     */
    async getSelf(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAggregate> {
        const response = await this.getSelfRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the currency of a business
     */
    async getSettingsCurrencyRaw(requestParameters: GetSettingsCurrencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Currency>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getSettingsCurrency().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/settings/businesses/{business_id}/currency`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrencyFromJSON(jsonValue));
    }

    /**
     * Get the currency of a business
     */
    async getSettingsCurrency(requestParameters: GetSettingsCurrencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Currency> {
        const response = await this.getSettingsCurrencyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the timezone of a business
     */
    async getSettingsTimezoneRaw(requestParameters: GetSettingsTimezoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Timezone>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getSettingsTimezone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mobile/settings/businesses/{business_id}/timezone`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimezoneFromJSON(jsonValue));
    }

    /**
     * Get the timezone of a business
     */
    async getSettingsTimezone(requestParameters: GetSettingsTimezoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Timezone> {
        const response = await this.getSettingsTimezoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns information about the status of the biserver service
     */
    async getStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusResponseFromJSON(jsonValue));
    }

    /**
     * Returns information about the status of the biserver service
     */
    async getStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusResponse> {
        const response = await this.getStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get thoughtspot liveboards
     */
    async getThoughtspotLiveboardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotLiveboardsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/liveboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThoughtspotLiveboardsResponseFromJSON(jsonValue));
    }

    /**
     * Get thoughtspot liveboards
     */
    async getThoughtspotLiveboards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotLiveboardsResponse> {
        const response = await this.getThoughtspotLiveboardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get thoughtspot schedules
     */
    async getThoughtspotSchedulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotSchedulesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThoughtspotSchedulesResponseFromJSON(jsonValue));
    }

    /**
     * Get thoughtspot schedules
     */
    async getThoughtspotSchedules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotSchedulesResponse> {
        const response = await this.getThoughtspotSchedulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get thoughtspot data sources for the search data feature
     */
    async getThoughtspotSearchDataDataSourcesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotDataSourcesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/search-data/data-sources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThoughtspotDataSourcesResponseFromJSON(jsonValue));
    }

    /**
     * Get thoughtspot data sources for the search data feature
     */
    async getThoughtspotSearchDataDataSources(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotDataSourcesResponse> {
        const response = await this.getThoughtspotSearchDataDataSourcesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get thoughtspot token
     */
    async getThoughtspotTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThoughtspotToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThoughtspotTokenFromJSON(jsonValue));
    }

    /**
     * Get thoughtspot token
     */
    async getThoughtspotToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThoughtspotToken> {
        const response = await this.getThoughtspotTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users by business
     */
    async getUsersByBusinessRaw(requestParameters: GetUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersByBusinessResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getUsersByBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{business_id}/users`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersByBusinessResponseFromJSON(jsonValue));
    }

    /**
     * Get users by business
     */
    async getUsersByBusiness(requestParameters: GetUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersByBusinessResponse> {
        const response = await this.getUsersByBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Impersonate another user
     */
    async impersonateRaw(requestParameters: ImpersonateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImpersonateResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling impersonate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ops/impersonate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImpersonateResponseFromJSON(jsonValue));
    }

    /**
     * Impersonate another user
     */
    async impersonate(requestParameters: ImpersonateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImpersonateResponse> {
        const response = await this.impersonateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update business
     */
    async patchBusinessesBusinessIdRaw(requestParameters: PatchBusinessesBusinessIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Business>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling patchBusinessesBusinessId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{business_id}`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessRequestToJSON(requestParameters['UpdateBusinessRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessFromJSON(jsonValue));
    }

    /**
     * Update business
     */
    async patchBusinessesBusinessId(requestParameters: PatchBusinessesBusinessIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Business> {
        const response = await this.patchBusinessesBusinessIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a budget file
     */
    async postBudgetRaw(requestParameters: PostBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling postBudget().'
            );
        }

        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling postBudget().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['year'] != null) {
            queryParameters['year'] = requestParameters['year'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{business_id}/upload`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload a budget file
     */
    async postBudget(requestParameters: PostBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postBudgetRaw(requestParameters, initOverrides);
    }

    /**
     * Change password
     */
    async postChangePasswordRaw(requestParameters: PostChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters['ChangePasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change password
     */
    async postChangePassword(requestParameters: PostChangePasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postChangePasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Create the respective Norma accounts for the selected foodics accounts
     */
    async postIntegrationsFoodicsAccountsInitialOnboardingRaw(requestParameters: PostIntegrationsFoodicsAccountsInitialOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Foodics_Initial_Onboarding", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/accounts/initial-onboarding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitialOnboardFoodicsUsersRequestToJSON(requestParameters['InitialOnboardFoodicsUsersRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create the respective Norma accounts for the selected foodics accounts
     */
    async postIntegrationsFoodicsAccountsInitialOnboarding(requestParameters: PostIntegrationsFoodicsAccountsInitialOnboardingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postIntegrationsFoodicsAccountsInitialOnboardingRaw(requestParameters, initOverrides);
    }

    /**
     * Invite Foodics accounts
     */
    async postInviteFoodicsAccountsRaw(requestParameters: PostInviteFoodicsAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/accounts/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteFoodicsUsersRequestToJSON(requestParameters['InviteFoodicsUsersRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Invite Foodics accounts
     */
    async postInviteFoodicsAccounts(requestParameters: PostInviteFoodicsAccountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postInviteFoodicsAccountsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postLoginRaw(requestParameters: PostLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters['LoginRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async postLogin(requestParameters: PostLoginRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.postLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postLogoutRaw(initOverrides);
    }

    /**
     * Onboard users in business
     */
    async postOnboardUsersToBusinessRaw(requestParameters: PostOnboardUsersToBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling postOnboardUsersToBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/{business_id}/accounts/onboard`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardFoodicsUsersRequestToJSON(requestParameters['OnboardFoodicsUsersRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Onboard users in business
     */
    async postOnboardUsersToBusiness(requestParameters: PostOnboardUsersToBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postOnboardUsersToBusinessRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postOpsLoginRaw(requestParameters: PostOpsLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ops/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminLoginRequestToJSON(requestParameters['AdminLoginRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async postOpsLogin(requestParameters: PostOpsLoginRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.postOpsLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request reset password
     */
    async postRequestResetPasswordRaw(requestParameters: PostRequestResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RequestResetPasswordResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/request-reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestResetPasswordRequestToJSON(requestParameters['RequestResetPasswordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResetPasswordResponseFromJSON(jsonValue));
    }

    /**
     * Request reset password
     */
    async postRequestResetPassword(requestParameters: PostRequestResetPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RequestResetPasswordResponse> {
        const response = await this.postRequestResetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset password
     */
    async postResetPasswordRaw(requestParameters: PostResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters['ResetPasswordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Reset password
     */
    async postResetPassword(requestParameters: PostResetPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.postResetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change business context
     */
    async postSelfBusinessContextRaw(requestParameters: PostSelfBusinessContextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self/business-context`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeBusinessContextRequestToJSON(requestParameters['ChangeBusinessContextRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change business context
     */
    async postSelfBusinessContext(requestParameters: PostSelfBusinessContextRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSelfBusinessContextRaw(requestParameters, initOverrides);
    }

    /**
     * Change enterprise web app liveboards order
     */
    async postSelfEnterpriseWebAppLiveboardsOrderRaw(requestParameters: PostSelfEnterpriseWebAppLiveboardsOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self/enterprise-web-app/liveboards-order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['LiveboardCategory']!.map(LiveboardCategoryToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change enterprise web app liveboards order
     */
    async postSelfEnterpriseWebAppLiveboardsOrder(requestParameters: PostSelfEnterpriseWebAppLiveboardsOrderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSelfEnterpriseWebAppLiveboardsOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Change enterprise web app mode
     */
    async postSelfEnterpriseWebAppModeRaw(requestParameters: PostSelfEnterpriseWebAppModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self/enterprise-web-app/mode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeModeRequestToJSON(requestParameters['ChangeModeRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change enterprise web app mode
     */
    async postSelfEnterpriseWebAppMode(requestParameters: PostSelfEnterpriseWebAppModeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSelfEnterpriseWebAppModeRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new liveboard
     */
    async postThoughtspotLiveboardsCreateRaw(requestParameters: PostThoughtspotLiveboardsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThoughtspotLiveboardsCreateResponse>> {
        if (requestParameters['liveboard_name'] == null) {
            throw new runtime.RequiredError(
                'liveboard_name',
                'Required parameter "liveboard_name" was null or undefined when calling postThoughtspotLiveboardsCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['liveboard_name'] != null) {
            queryParameters['liveboard_name'] = requestParameters['liveboard_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/liveboards/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThoughtspotLiveboardsCreateResponseFromJSON(jsonValue));
    }

    /**
     * Create a new liveboard
     */
    async postThoughtspotLiveboardsCreate(requestParameters: PostThoughtspotLiveboardsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThoughtspotLiveboardsCreateResponse> {
        const response = await this.postThoughtspotLiveboardsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unfavorite thoughtspot liveboard
     */
    async unfavoriteThoughtspotLiveboardRaw(requestParameters: UnfavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['liveboard_id'] == null) {
            throw new runtime.RequiredError(
                'liveboard_id',
                'Required parameter "liveboard_id" was null or undefined when calling unfavoriteThoughtspotLiveboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['liveboard_id'] != null) {
            queryParameters['liveboard_id'] = requestParameters['liveboard_id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/liveboards/favorite`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unfavorite thoughtspot liveboard
     */
    async unfavoriteThoughtspotLiveboard(requestParameters: UnfavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unfavoriteThoughtspotLiveboardRaw(requestParameters, initOverrides);
    }

    /**
     * Promote user to admin
     */
    async usersPromoteUserRaw(requestParameters: UsersPromoteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['user_id'] == null) {
            throw new runtime.RequiredError(
                'user_id',
                'Required parameter "user_id" was null or undefined when calling usersPromoteUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/promote`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['user_id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Promote user to admin
     */
    async usersPromoteUser(requestParameters: UsersPromoteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersPromoteUserRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetBranchAppsNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchAppsNetSalesTimePeriodEnum = typeof GetBranchAppsNetSalesTimePeriodEnum[keyof typeof GetBranchAppsNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchGeneralMetricsTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchGeneralMetricsTimePeriodEnum = typeof GetBranchGeneralMetricsTimePeriodEnum[keyof typeof GetBranchGeneralMetricsTimePeriodEnum];
/**
 * @export
 */
export const GetBranchNetSalesTimeseriesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchNetSalesTimeseriesTimePeriodEnum = typeof GetBranchNetSalesTimeseriesTimePeriodEnum[keyof typeof GetBranchNetSalesTimeseriesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchOrderSourcesNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchOrderSourcesNetSalesTimePeriodEnum = typeof GetBranchOrderSourcesNetSalesTimePeriodEnum[keyof typeof GetBranchOrderSourcesNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchOrderTagsNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchOrderTagsNetSalesTimePeriodEnum = typeof GetBranchOrderTagsNetSalesTimePeriodEnum[keyof typeof GetBranchOrderTagsNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchOrderTypesNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchOrderTypesNetSalesTimePeriodEnum = typeof GetBranchOrderTypesNetSalesTimePeriodEnum[keyof typeof GetBranchOrderTypesNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchesNetSalesTimeseriesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchesNetSalesTimeseriesTimePeriodEnum = typeof GetBranchesNetSalesTimeseriesTimePeriodEnum[keyof typeof GetBranchesNetSalesTimeseriesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchesReportTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchesReportTimePeriodEnum = typeof GetBranchesReportTimePeriodEnum[keyof typeof GetBranchesReportTimePeriodEnum];
/**
 * @export
 */
export const GetBudgetResolutionEnum = {
    weekly: 'weekly',
    monthly: 'monthly'
} as const;
export type GetBudgetResolutionEnum = typeof GetBudgetResolutionEnum[keyof typeof GetBudgetResolutionEnum];
/**
 * @export
 */
export const GetBudgetTemplateResolutionEnum = {
    weekly: 'weekly',
    monthly: 'monthly'
} as const;
export type GetBudgetTemplateResolutionEnum = typeof GetBudgetTemplateResolutionEnum[keyof typeof GetBudgetTemplateResolutionEnum];
/**
 * @export
 */
export const GetBudgetXlsxResolutionEnum = {
    weekly: 'weekly',
    monthly: 'monthly'
} as const;
export type GetBudgetXlsxResolutionEnum = typeof GetBudgetXlsxResolutionEnum[keyof typeof GetBudgetXlsxResolutionEnum];
/**
 * @export
 */
export const GetMenuMixBranchProductsWithNetSalesAndQuantityTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBranchProductsWithNetSalesAndQuantityTimePeriodEnum = typeof GetMenuMixBranchProductsWithNetSalesAndQuantityTimePeriodEnum[keyof typeof GetMenuMixBranchProductsWithNetSalesAndQuantityTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBranchProductsWithNetSalesAndQuantityOrderByEnum = {
    net_sales: 'net-sales',
    quantity: 'quantity'
} as const;
export type GetMenuMixBranchProductsWithNetSalesAndQuantityOrderByEnum = typeof GetMenuMixBranchProductsWithNetSalesAndQuantityOrderByEnum[keyof typeof GetMenuMixBranchProductsWithNetSalesAndQuantityOrderByEnum];
/**
 * @export
 */
export const GetMenuMixBusinessBranchesWithQuantityTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBusinessBranchesWithQuantityTimePeriodEnum = typeof GetMenuMixBusinessBranchesWithQuantityTimePeriodEnum[keyof typeof GetMenuMixBusinessBranchesWithQuantityTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityTimePeriodEnum = typeof GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityTimePeriodEnum[keyof typeof GetMenuMixBusinessProductBranchesWithNetSalesAndQuantityTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBusinessProductCategoriesWithNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBusinessProductCategoriesWithNetSalesTimePeriodEnum = typeof GetMenuMixBusinessProductCategoriesWithNetSalesTimePeriodEnum[keyof typeof GetMenuMixBusinessProductCategoriesWithNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBusinessProductCategoryBranchesWithNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBusinessProductCategoryBranchesWithNetSalesTimePeriodEnum = typeof GetMenuMixBusinessProductCategoryBranchesWithNetSalesTimePeriodEnum[keyof typeof GetMenuMixBusinessProductCategoryBranchesWithNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesTimePeriodEnum = typeof GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesTimePeriodEnum[keyof typeof GetMenuMixBusinessProductCategoryOrderTagsWithNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityTimePeriodEnum = typeof GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityTimePeriodEnum[keyof typeof GetMenuMixBusinessProductOrderTagsWithNetSalesAndQuantityTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBusinessProductsQuantityTimeseriesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBusinessProductsQuantityTimeseriesTimePeriodEnum = typeof GetMenuMixBusinessProductsQuantityTimeseriesTimePeriodEnum[keyof typeof GetMenuMixBusinessProductsQuantityTimeseriesTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBusinessProductsWithNetSalesAndQuantityTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetMenuMixBusinessProductsWithNetSalesAndQuantityTimePeriodEnum = typeof GetMenuMixBusinessProductsWithNetSalesAndQuantityTimePeriodEnum[keyof typeof GetMenuMixBusinessProductsWithNetSalesAndQuantityTimePeriodEnum];
/**
 * @export
 */
export const GetMenuMixBusinessProductsWithNetSalesAndQuantityOrderByEnum = {
    net_sales: 'net-sales',
    quantity: 'quantity'
} as const;
export type GetMenuMixBusinessProductsWithNetSalesAndQuantityOrderByEnum = typeof GetMenuMixBusinessProductsWithNetSalesAndQuantityOrderByEnum[keyof typeof GetMenuMixBusinessProductsWithNetSalesAndQuantityOrderByEnum];
