import React from "react";
import { Dialog } from "@/components/Dialog";
import { useToggleAppMode } from "@/hooks/useToggleAppMode";
import { ChangeModeRequestModeEnum as AppMode } from "@norma-bi/bi-api";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { motion } from "framer-motion";
import { Liveboard } from "@/pages/LiveboardEmbed";
import { SearchEmbedPage } from "@/pages/SearchEmbed";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Spotter } from "@/pages/SpotterEmbed";
import { TooltipProvider } from "@/components/Tooltip";
import "./index.css";

export function LiteLayout() {
  const { mode, toggleAppMode } = useToggleAppMode();
  const [showSwitchDialog, setShowSwitchDialog] = React.useState(false);
  const location = useLocation();
  const isAnswerPath = location.pathname === "/answer";
  const isSettingsPath = location.pathname.startsWith("/settings");
  const isSpotterPath = location.pathname === "/spotter";

  if (!mode) {
    return null;
  }

  return (
    <TooltipProvider>
      <div className="flex flex-col h-full bg-lightGray">
        <motion.div
          className="lite-layout-container flex flex-col h-full"
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeInOut" }}
        >
          <Header mode={mode} />
          <div className="flex flex-1 overflow-hidden">
            {isAnswerPath ? (
              <main className="flex-grow pr-5 bg-lightGray">
                <SearchEmbedPage />
              </main>
            ) : isSettingsPath ? (
              <main className="flex-grow pr-5 bg-lightGray">
                <Outlet />
              </main>
            ) : isSpotterPath ? (
              <main className="flex-grow pr-5 bg-lightGray">
                <Spotter />
              </main>
            ) : (
              <>
                <Sidebar />
                <main className="flex-grow mt-4 pr-5 bg-lightGray">
                  <Liveboard />
                </main>
              </>
            )}
            <Dialog
              show={showSwitchDialog}
              title="Switch Mode"
              message={`Are you sure you want to switch to ${mode === AppMode.pro ? "Lite" : "Pro"} Mode?`}
              onConfirm={toggleAppMode}
              onCancel={() => setShowSwitchDialog(false)}
              confirmText="Confirm"
              cancelText="Cancel"
              className="h-[212px]"
            />
          </div>
        </motion.div>
      </div>
    </TooltipProvider>
  );
}
