import { useTooltip, TooltipContent } from "@/components/Tooltip";

export function useSidebarTooltip<T extends HTMLElement>(
  content: TooltipContent,
  delay: number = 1000,
) {
  return useTooltip<T>(content, {
    delay,
    position: (rect) => ({
      x: rect.right + rect.width / 2,
      y: rect.top,
    }),
  });
}
