import { Link, useLocation } from "react-router";

export type SettingsSidebarItem = {
  path: string;
  label: string;
  soon?: boolean;
};

export type SettingsSidebarGroup = {
  header: string;
  items: SettingsSidebarItem[];
};

export type SettingsSidebarProps = {
  groups: SettingsSidebarGroup[];
};

export function SettingsSidebar({ groups }: SettingsSidebarProps) {
  const location = useLocation();

  const Item = ({ item }: { item: SettingsSidebarItem }) => {
    const isActive = location.pathname.endsWith(item.path);
    const isDisabled = item.soon;

    const Container = (props: React.PropsWithChildren) => {
      if (isDisabled) {
        return props.children;
      }
      return <Link to={item.path}>{props.children}</Link>;
    };

    return (
      <Container>
        <h3
          className={`text-sm pt-2 px-[62px] p-2 ${
            isActive
              ? "bg-backgroundGray text-dark font-medium"
              : isDisabled
                ? "text-[#EAEDF2] cursor-not-allowed"
                : "hover:bg-backgroundGray hover:text-dark cursor-pointer"
          }`}
        >
          {item.label} {item.soon && "(soon)"}
        </h3>
      </Container>
    );
  };

  const Group = ({ group }: { group: SettingsSidebarGroup }) => {
    return (
      <div className="flex flex-col gap-2">
        <h2 className="text-sm font-medium mb-2 pt-2 px-[56px]">{group.header}</h2>
        {group.items.map((item) => {
          return <Item key={item.path} item={item} />;
        })}
      </div>
    );
  };

  return (
    <div className="w-64 h-full bg-gray-100 border-r border-[#EAEDF2] ml-12">
      <h1 className="text-lg font-medium my-3 w-[260px] h-[32px] py-6 px-11">Settings</h1>
      {groups.map((group) => (
        <Group key={group.header} group={group} />
      ))}
    </div>
  );
}
