import clsx from "clsx";
import { SvgIcon, SvgIconType } from "@/svg";
import { useSidebarTooltip } from "./hooks/useSidebarTooltip";

type SidebarButtonProps = {
  icon: SvgIconType;
  onClick: () => void;
  rotate?: boolean;
  tooltipProps?: React.HTMLProps<HTMLDivElement>;
};

export function SidebarButton({ icon, onClick, rotate = false, tooltipProps }: SidebarButtonProps) {
  return (
    <div className="h-10 w-10" {...tooltipProps}>
      <button className="h-full w-full" onClick={onClick}>
        <div
          className={clsx(
            "h-full w-full rounded-lg flex items-center justify-center",
            "bg-white text-feintGray hover:text-dark shadow-button",
          )}
        >
          <div className={clsx(rotate && "rotate-180")}>
            <SvgIcon icon={icon} />
          </div>
        </div>
      </button>
    </div>
  );
}

export function CollapseButton({
  isExpanded,
  onClick,
}: {
  isExpanded: boolean;
  onClick: () => void;
}) {
  const tooltipProps = useSidebarTooltip<HTMLDivElement>(isExpanded ? "Collapse" : "Expand");

  return (
    <SidebarButton
      icon="DoubleChevronLeft"
      onClick={onClick}
      rotate={!isExpanded}
      tooltipProps={tooltipProps}
    />
  );
}

export function AddLiveboardButton({ onClick }: { onClick: () => void }) {
  const tooltipProps = useSidebarTooltip<HTMLDivElement>("Add Liveboard");

  return <SidebarButton icon="Plus" onClick={onClick} tooltipProps={tooltipProps} />;
}
