import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "@/api";
import { LiveboardsQueryKey } from "@/hooks/useLiveboards";
import { useThoughtSpotToken } from "@/thoughtspot";
import { createThoughtSpotHeaders } from "@/api/thoughtspot";

type CreateLiveboardArgs = {
  liveboard_name: string;
};

export function useCreateLiveboard() {
  const api = useAPI();
  const queryClient = useQueryClient();
  const thoughtspotToken = useThoughtSpotToken((state) => state.token);

  return useMutation({
    mutationFn: async (args: CreateLiveboardArgs) => {
      if (!thoughtspotToken) {
        throw new Error("ThoughtSpot token is required");
      }
      const response = await api.postThoughtspotLiveboardsCreate(
        {
          liveboard_name: args.liveboard_name,
        },
        createThoughtSpotHeaders(thoughtspotToken!),
      );
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [LiveboardsQueryKey] });
    },
  });
}
