import { SvgIcon } from "../svg";
import { Input } from "./Input";

export type SearchBoxProps = {
  term: string;
  onTermChanged: (term: string) => void;
  placeholder?: string;
  variant?: "classic" | "darker";
};

export function SearchBox(props: SearchBoxProps) {
  let colorClassName = "";
  if (props.variant === "darker") {
    colorClassName = `${colorClassName} !bg-lightGray`;
  } else {
    colorClassName = `${colorClassName}`;
  }

  return (
    <Input
      icon={<SvgIcon icon="Search" />}
      type="search"
      placeholder={props.placeholder}
      value={props.term}
      onChange={(e) => props.onTermChanged(e.target.value)}
      containerClassName={colorClassName}
      className={colorClassName}
    />
  );
}
