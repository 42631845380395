export function DefaultTooltipRenderer({
  tooltip,
}: {
  tooltip: { content: string; x: number; y: number } | null;
}) {
  if (!tooltip) return null;
  return (
    <div
      className="absolute bg-white p-2 rounded shadow-lg whitespace-nowrap text-sm"
      style={{
        top: tooltip.y,
        left: tooltip.x,
      }}
    >
      <span className="text-feintGray font-medium">{tooltip.content}</span>
    </div>
  );
}
